<script>
import {AgGridVue} from "ag-grid-vue";
import {mapState} from "vuex";
import StatusDisplayCell from "../grid/StatusDisplayCell";
import DateCell from "../grid/generic/DateCell";
import ActionsDisplayCell from "../grid/ActionsDisplayCell";
import store from '@/store';

export default {
  name: "UsersGrid",
  data() {
    return {
      columnDefs: null,
      gridApi: null,
      columnApi: null
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    AgGridVue, StatusDisplayCell, DateCell, ActionsDisplayCell
  },
  beforeMount() {
    this.columnDefs = [
      {
        field: "name",
        resizable: true,
        headerName: "User Name",
        cellStyle: {'text-align': 'left'},
        width: 300
      },
      {
        field: "email",
        resizable: true,
        headerName: "Email",
        cellStyle: {'text-align': 'left'},
        width: 300
      },
      {
        field: "role",
        resizable: true,
        headerName: "Role",
        cellStyle: {'text-align': 'left'},
        width: 300,
        cellRenderer: (params) => {
          try {
            return params.value.toUpperCase();
          } catch (e) {
            console.log(params.value);
          }
        }
      },
      {
        field: "instances",
        resizable: true,
        headerName: "Allowed Instances",
        cellStyle: {'text-align': 'left'},
        width: 300,
        cellRenderer: (params) => {
          try {
            return params.value.toUpperCase();
          } catch (e) {
            console.log(params.value);
          }
        }
      },
      {
        field: "id",
        headerName: "Actions",
        width: 210,
        cellStyle: {'text-align': 'left'},
        cellRenderer: 'ActionsDisplayCell',
        cellRendererParams: {
          actions: [
            {
              type: 'primary',
              label: 'Edit',
              clickHandler: {
                type: 'dispatch',
                action:'users/openAddModal'
              }
            },
            {
              type: 'danger',
              label: 'De-activate',
              clickHandler: {
                type: 'dispatch',
                action: 'users/deactivateUser',
                onSuccess: function () {
                  store.dispatch('users/fetchUsers');
                }
              },
            }
          ]
        }
      }
    ];
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    sizeToFit() {
      this.gridApi.sizeColumnsToFit();
    },
    fetchUsers() {
      this.$store.dispatch('users/fetchUsers');
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.sizeToFit();
    }
  },
  computed: mapState({
    rowData: state => state.users.users,
    isLoading: state => state.users.isFetching
  })
};
</script>

<template>
  <b-overlay :show="this.isLoading" rounded="sm">
    <ag-grid-vue style=" width: 100%; height: 700px;"
                 class="ag-theme-alpine"
                 :columnDefs="columnDefs"
                 :rowData="rowData">
    </ag-grid-vue>
  </b-overlay>
</template>
