<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Users</h4>

          <div class="page-title-right">
            <ol class="breadcrumb m-0">
              <li class="breadcrumb-item active">
                <b-dropdown text="Actions" variant="primary">
                  <b-dropdown-item @click="openModal">Create user</b-dropdown-item>
                </b-dropdown>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="col-12">
        <UsersGrid/>
      </div>
    </div>
    <UserModal />
  </Layout>
</template>

<script>
import Layout from '../layouts/horizontal.vue';
import UserModal from "../components/users/UserModal";
import UsersGrid from "../components/users/UsersGrid";

export default {
  name: 'Pricelists',
  components: {
    UsersGrid,
    UserModal,
    Layout
  },
  methods: {
    openModal() {
      this.$store.dispatch('users/openAddModal');
    }
  },
}
</script>
