<template>
  <b-modal id="price-list-modal" size="lg" title="Create/Edit User" v-model="this.showUsersModal">
    <b-overlay :show="loading" rounded="sm">
      <p>Please fill in the user's details and press Save</p>
      <b-alert variant="danger" :show="this.errors.length > 0">
        <ul>
          <li v-for="error in this.errors" :key="error">{{ error }}</li>
        </ul>
      </b-alert>

      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="user_name">Name</label>
          </div>
          <div class="formElement__input">
            <b-form-input v-model="user.name" id="user_name" placeholder="Please enter"></b-form-input>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label for="user_email">Email address</label>
          </div>
          <div class="formElement__input">
            <b-form-input v-model="user.email" id="user_email" type="email" placeholder="Please enter"></b-form-input>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label>Role</label>
          </div>
          <div class="formElement__input">
            <v-select name="currency-select" id="role-select" :placeholder="`Please select`" v-model="user.role"
                      label="text"
                      :options="roles"></v-select>
          </div>
        </div>
      </div>
      <div class="formRow">
        <div class="formElement">
          <div class="formElement__label">
            <label>Instances Allows</label>
          </div>
          <div class="formElement__input">
            <v-select name="currency-select" id="role-select" multiple :placeholder="`Please select`" v-model="user.instances"
                      label="text" value="code"
                      :options="instances"></v-select>
          </div>
        </div>
      </div>

      <div v-if="this.mode === 'add' || (this.mode === 'edit' && this.showPasswordFieldsInEdit === true)">
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label>Password</label>
            </div>
            <div class="formElement__input">
              <b-form-input v-model="user.password" id="user_password" type="password"
                            placeholder="Please enter"></b-form-input>
            </div>
          </div>
        </div>
        <div class="formRow">
          <div class="formElement">
            <div class="formElement__label">
              <label>Confirm password</label>
            </div>
            <div class="formElement__input">
              <b-form-input id="user_password_confirm" v-model="user.password_confirmation" type="password"
                            placeholder="Please enter"></b-form-input>
            </div>
          </div>
        </div>
      </div>


    </b-overlay>
    <template #modal-footer>
      <b-button variant="warning" v-if="mode === 'edit'" @click="togglePasswordFields">
        Update Password
      </b-button>
      <b-button variant="success" @click="saveUser">
        Save
      </b-button>
      <b-button variant="light" @click="reset">
        Cancel
      </b-button>
    </template>

  </b-modal>
</template>

<script>

import {mapState} from "vuex";

let defaultUser = {
  id: null,
  name: null,
  email: null,
  password: null,
  password_confirmation: null,
  instances: null,
  role: null
}
export default {
  name: "UserModal",
  data() {
    return {
      mode: 'add',
      showPasswordFieldsInEdit: false,
      loading: false,
      user: defaultUser,
      errors: [],
      roles: [
        {
          text: 'Administrator',
          value: 'admin'
        },
        {
          text: 'Regular user',
          value: 'user'
        }
      ],
      instances: [
        {
          text: 'Beverages',
          value: 'beverages'
        },
        {
          text: 'Health & Beauty',
          value: 'hnb'
        }
      ]
    }
  },
  components: {},
  computed: {
    showUsersModal: {
      get() {
        return this.$store.state.users.modals.isAddModalOpen
      },
      set(v) {
        if (v) {
          this.$store.dispatch('users/closeAddModal');
        } else {
          this.$store.dispatch('users/openAddModal');
        }
      }
    },
    ...mapState({currentUser: state => state.users.currentUser})
  },
  watch: {
    currentUser(newUser) {
      if (newUser === null) {
        this.user = defaultUser;
        this.mode = 'add';
      } else {
        this.user = newUser;
        this.user.role = this.roles.find(item => item.value === newUser.role)
        this.user.instances = this.instances.filter((_) => this.user.instances.includes(_.value))
        this.mode = 'edit';
      }
    }
  },
  methods: {

    togglePasswordFields() {
      this.showPasswordFieldsInEdit = !this.showPasswordFieldsInEdit;
    },
    saveUser() {

      if (!this.validate()) {
        return false;
      }

      this.loading = true;

      let action = this.mode === 'add' ? 'users/createUser' : 'users/updateUser';

      if (this.user.role.label) {
        this.user.role = this.user.role.value;
      }

      this.$store.dispatch(action, this.user).then(() => {
        this.$store.dispatch('users/fetchUsers');
        this.reset();
      });
    },

    reset() {

      this.loading = false;
      this.$store.dispatch('users/closeAddModal');
    },
    validate() {

      this.errors = [];

      if (!this.user.name) {
        this.errors.push('Please enter a user name');
      }
      if (!this.user.email) {
        this.errors.push('Please enter a valid email');
      }
      if (!this.user.password || this.user.password !== this.user.password_confirmation) {
        if (this.mode === 'add') {
          this.errors.push('Please enter a password and ensure it is the same in both inputs');
        }
      }

      if (!this.user.role) {
        this.errors.push('Please select a role for this user');
      }

      return this.errors.length === 0;

    }
  }

}
</script>
